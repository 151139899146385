import { Box, Flex, Image, Tag, TagLabel, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { v4 as uuid } from 'uuid';

import UserInfo from '../UserInfo';

import { useNavigate } from 'react-router-dom';

import Votes from '../Votes';

const StoryPostCard = ({ isBook, story }) => {

    const navigate = useNavigate();

    const handleCardClick = () => {
        navigate(`/story/${story.id}`);
    };

    return (
        <Flex
            flexDirection="column"
            fontWeight="normal"
            width="100%"
            marginBottom={4}
        >

            <Box
                bg="white"
                _hover={{ transform: "scale(1.02)", transition: "0.2s" }}
                onClick={handleCardClick}
                boxShadow="xl"
                p={4}
                p={4}
                borderRadius="md"
            >

            {(isBook) ? null : <UserInfo id={story.user} />}

            <VStack align="start" spacing={3}>
                <Text marginBottom={'-7px'} fontSize="2xl" fontWeight="bold">
                    {story.title}
                </Text>
                <Text fontSize={"xl"} fontWeight={"normal"} noOfLines={3}>{story.synopsis}</Text>
                <Box>

                    <Flex mb={"4px"} justifyContent={"flex-start"} flexWrap={"wrap"} display="flex" gap={"8px"} flexDirection={"row"}>
                        {story.ancestryTag ?
                            <Tag fontWeight={"semibold"} height={"23px"} size="md" colorScheme="blue" borderRadius="10px">
                                <TagLabel fontWeight={"semibold"}>{story.ancestryTag}</TagLabel>
                            </Tag>
                            : null}
                        {story.originsTag.slice(0, 3).map((origin) => (
                            <Tag size="md" bg="gray.300" borderRadius="10px" lineHeight="1" key={uuid()}>
                                <Text fontSize="23px" px="0.2rem">{origin}</Text>
                            </Tag>
                        ))}
                        {story.typesTag.slice(0, 3).map((type) => (
                                <Tag fontWeight={"semibold"} height={"23px"} size="md" colorScheme="purple" borderRadius="10px">
                                    <TagLabel fontWeight={"semibold"}>{type}</TagLabel>
                                </Tag>
                        ))}
                    </Flex>

                </Box>
                {(story.mainImage) ?
                    <Image
                        borderRadius="md"
                        src={story.mainImage}
                        width="100%"
                        height="200px"
                        objectFit="cover"
                    />
                    : null}

                {/* { (!isBook) ? */}
                    <Flex justify={"flex-end"} alignSelf={"center"}>
                        <Votes postId={story.storyCode} postType={"stories"} />
                    </Flex>
                {/* : null} */}

            </VStack>
        </Box>
        </Flex>
    );
};

export default StoryPostCard;
